import React from 'react'
import styled from 'styled-components'
import whatsapp from '../../images/icons8-whatsapp-96.png'

export default () => {

    const PHONE_NUMBER = '+5491170818213';
    return (
        <ContainerIMGWhsap href={`https://api.whatsapp.com/send?phone=${PHONE_NUMBER}&text=Hola!%20quería%20consultar%20por...`}>
            <img src={whatsapp} alt='whatsapp'/>
        </ContainerIMGWhsap>
    )
};


const ContainerIMGWhsap = styled.a`
    width: 70px;
    height: 70px;

    position: fixed;
    right: 30px;
    bottom: 5%;
    img{
        width: 100%;
        height: 100%;
    };
    z-index: 99;

`;