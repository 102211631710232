import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import React from "react";
import ReliableIconImage from "../../images/reliable-icon.svg";
import { SectionHeading } from "components/misc/Headings.js";
import ShieldIconImage from "../../images/shield-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";
import SupportIconImage from "../../images/support-icon.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import defaultCardImage from "../../images/shield-icon.svg";
import styled from "styled-components";
import tw from "twin.macro";

const Container = tw.div `relative`;

const ThreeColumnContainer = styled.div `
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: ShieldIconImage,
      title: "Infraestructura",
      description: "Dimensionamos la infraestructura necesaria de sus sistemas para mejorar la funcionalidades de su red, de almacenamiento de información y de procesamiento de datos al nivel deseado."
    },
    { imageSrc: CustomizeIconImage, title: "Redes y comunicaciones", substitle: "Diseñamos y administramos las redes, en forma cableada o inalambrica asegurando el perímetro y la confiabilidad de la comunicacion" },
    { imageSrc: ReliableIconImage, title: "Disaster recovery", substitle: "Cree servicios de TI resistentes, disponibles y recuperables que cumplan con los requisitos de continuidad de su negocio" },
    { imageSrc: SupportIconImage, title: "Sistema de CCTV", substitle: "Aumente la vigilancia perimetral y seguridad de su empresa o estructura con sistemas de video vigilancia con IA"},
    { imageSrc: FastIconImage, title: "Desarrollos", substitle: "Realizamos integraciones de servicios y desarrollos a sus sitemas " },
    { imageSrc: SimpleIconImage, title: "Product Expansion", substitle: "Contenido" }
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>Nuestros <span tw="text-primary-500">servicios</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || card.substitle}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};