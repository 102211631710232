import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import { ReactComponent as CheckIcon } from "feather-icons/dist/icons/check.svg";
import { ReactComponent as CodePenIcon } from "feather-icons/dist/icons/codepen.svg";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import FAQ from "components/faqs/SingleCol.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import Hero from "components/hero/BackgroundAsImage.js";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import MainFeature4 from "components/features/TwoColSingleFeatureWithStats4.js";
import MainFeaturePerformance from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import Monitoring from "components/features/TwoColWithButton.js";
import Pilares from "../surit/Pilares.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Pricing from "components/pricing/ThreePlans.js";
import React from "react";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import WhatsappComponent from "components/WhatsappComponent/WhatsappComponent.js";
import { css } from "styled-components/macro"; //eslint-disable-line
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import desarrolloImg from "images/desarrollo.jpg";
import infraestructuraImg from "images/infraestructura.svg";
import monitorImg from "images/dash_surit2.svg";
import tw from "twin.macro"; //eslint-disable-line

const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
const HighlightedText = tw.span`text-primary-500`;

export default () => (
  <>
  <WhatsappComponent/>
  <AnimationRevealPage>
    <Hero />
    {/* <MainFeature />!!!*/}
    {/* Agregados!!!*/}
    {/* --------!!!*/}
    <Pilares />
    <MainFeature4 />
    {/* --------!!!
    <Features />
    <Portfolio />*/}
    <div id="services">
    <Testimonial
      subheading="Servicios"
      heading={
        <>
          Un lugar, <span tw="text-primary-500"> mil servicios</span>
        </>
      }
      description="Contamos con una amplia gama de servicios para que pueda enfocarse en su negocio delegando el mantenimiento y la gestion de sus servicios y activos tecnológicos en nuestras manos. "
      testimonials={[
        {
          imageSrc:
            "./images/redes.jpg",
          profileImageSrc:
            "./images/redes.jpg",
          quote:
            "asdasdasdas",
          customerName: "Charlotte Hale",
          customerTitle: "CEO, Tesla Inc."
        },
        {
          imageSrc:
            "https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
          profileImageSrc:
            "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
          quote:
            "aaaa Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.",
          customerName: "Adam Cuppy",
          customerTitle: "Founder, Nestle"
        }
      ]}
      textOnLeft={true}
    />
    </div>
    <Monitoring
        subheading={<Subheading>Monitoreo y Automatización</Subheading>}
        imageSrc={monitorImg}
        imageBorder={true}
        imageDecoratorBlob={true}
    />

    <MainFeaturePerformance
        subheading={<Subheading>Optimice su estructura</Subheading>}
        heading={
          <>
            Mejore la <HighlightedText>performance</HighlightedText>
          </>
        }
        imageSrc={infraestructuraImg}
        imageRounded={false}
        imageBorder={false}
        imageShadow={false}
        iconRoundedFull={false}
        iconFilled={false}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Costos",
            description: "Ahorre en Licenciamiento y Hardware.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: CodePenIcon,
            title: "Recursos",
            description: "Optimice recursos y logre respaldo",
            iconContainerCss: tw`bg-red-300 text-red-800`
          },
          {
            Icon: CheckIcon,
            title: "Control",
            description: "Verifique en forma proactiva todo su sistema y activos",
            iconContainerCss: tw`bg-blue-300 text-blue-800`
          }
        ]}
      />
    <div id="solutions">
    <FeatureWithSteps
        subheading={<Subheading>Integracion & desarrollos </Subheading>}
        heading={
          <>
            Soluciones <HighlightedText> a medida.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={desarrolloImg}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
    </div>
    
    <FAQ
        subheading={<Subheading> </Subheading>}
        heading={
          <>
            Preguntas <HighlightedText>Generales ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "¿Qué tipo de soluciones tecnológicas ofrecen?",
            answer:
              "Ofrecemos soluciones a medida y a pedido como Automatizacion y Monitoreo, Backup & recovery, desarrollos IoT, etc para mantener su organización de manera eficiente y sin cortes de productividad. "
          },
          {
            question: "¿Cómo pueden ayudar a mejorar la eficiencia y productividad de mi empresa?",
            answer:
              "Buscamos optimizar la utilización de recursos de hardware y software, reducir la cantidad de procesos y servicios en ejecución, ajustar la configuración y tender a realizar un mantenimiento periódico más eficiente y con menor tiempo de impacto en la actividad cotidianas de su estructura o negocio."
          },
          {
            question: "¿Cómo garantizan la seguridad y protección de los datos de mi empresa?",
            answer:
              "Trabajamos con un acuerdo de confidencialidad establecido con cada uno de nuestros clientes y con procedimientos de trabajo seguros y recuperables. Algunas de las maneras son el respaldo de datos de forma fisica y en la nube. Una fuerte protección de ciberseguridad. "
          },
          {
            question: "¿Qué tipo de soporte técnico ofrecen?",
            answer:
              "Ofrecemos servicio postventa en todos nuestros servicios y soluciones. Los distintos niveles y opciones serán acorde a la necesidad del cliente, de manera presencial o remota y telefónica, brindada por personal profesional y calificado para dicha tarea."
          },
        
          {
            question: "¿Cuales son las ventajes de un servicio integral de IT ?",
            answer: " Los principales beneficios de los servicios de TI administrados o centralizados en un proveedor son la tranquilidad y el ahorro de costos generales en mantenimiento. Tranquilidad porque sabe que sus datos e infraestructura están protegidos contra los ataques cibernéticos y porque puede llamar al soporte de TI en cualquier momento que lo necesite. Los costos generales más bajos a menudo sorprenden a los líderes empresariales que no se dan cuenta de que las filtraciones de datos, el incumplimiento y el tiempo de inactividad empresarial terminan siendo muchos más costosos."
          }
        ]}
      />

    <Pricing
        subheading={<Subheading>Abonos</Subheading>}
        heading={
          <>
            Con planes ajustables y <HighlightedText>flexibles.</HighlightedText>
          </>
        }
        plans={[
          {
            price: "Basico",
            duration: "Soporte mínimo",
            mainFeature: "Baja demanda",
            features: ["Administracion y control de hardware", "Monitoreo automatizado", "Soporte : Remoto, telefónico y por e-mail"]
          },
          {
            price: "Estandar",
            duration: "Medianas o pymes",
            mainFeature: "Medida demanda",
            features: ["Administracion y control de hardware", "Mantenimiento de infraestructura", "Monitoreo automatizado", "Soporte : Remoto, telefónico y por e-mail", "Visitas presenciales a coordinar"],
            featured: true
          },
          {
            price: "Completo",
            duration: "Alto nivel de criticidad",
            mainFeature: "Alta demanda",
            features: ["Administracion y control de hardware", "Mantenimiento de infraestructura", "Monitoreo automatizado", "Soporte : Remoto, telefónico y por e-mail", "Visitas presenciales a coordinar", "Paquetes de horas destinadas a consultoria o capacitación", " Visitas presenciales ante incidentes 24/7"],
          }
        ]}
      />
    {/*<Blog />*/}
    <ContactUsForm />
    <Footer />
  </AnimationRevealPage>
  </>
);
