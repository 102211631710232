import "slick-carousel/slick/slick.css";

import React, { useState } from "react";
import { SectionHeading, Subheading as SubheadingBase } from "../misc/Headings.js";

import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { PrimaryButton } from "../misc/Buttons.js";
import { ReactComponent as QuotesLeftIcon } from "../../images/quotes-l.svg";
import { ReactComponent as QuotesRightIcon } from "../../images/quotes-r.svg";
import Slider from "react-slick";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-4.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-5.svg";
import { css } from "styled-components/macro"; //eslint-disable-line
import imgProfileCctv from "images/profile_cctv.jpg";
import imgProfileInfra from "images/profile_infra.jpg";
import imgProfileIot from "images/profile_iot.jpg";
import imgProfileRedes from "images/profile_redes.jpg";
import imgProfileSeg from "images/profile_seg.jpg";
import imgServiceCctv from "images/cctvService.jpeg";
import imgServiceInfra from "images/servidores.jpg";
import imgServiceIot from "images/IOT.jpg";
import imgServiceRedes from "images/redes.jpg";
import imgServiceSegInf from "images/ciberseg.png";
import styled from "styled-components";
import tw from "twin.macro";

const Container = tw.div `relative`;
const Content = tw.div `max-w-screen-xl mx-auto py-20 lg:py-24`;
const TestimonialsContainer = tw.div `mt-16 lg:mt-0`;
const Testimonials = styled.div ``;
const Testimonial = tw.div `max-w-md lg:max-w-none mx-auto lg:mx-0 flex flex-col items-center lg:items-stretch lg:flex-row`;

const TestimonialImageSlider = tw(Slider)
`w-full lg:w-5/12 flex-shrink-0 `;
const TestimonialTextSlider = tw(Slider)
``;
const TestimonialText = tw.div `outline-none`;

const ImageAndControlContainer = tw.div `relative outline-none`;
const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw `rounded bg-cover bg-center h-72 sm:h-96 lg:h-128`
]);

const ControlContainer = tw.div `absolute bottom-0 right-0 bg-gray-100 px-6 py-4 rounded-tl-3xl border`;
const ControlButton = styled(PrimaryButton)
`
  ${tw`mx-3 rounded-full text-gray-100 p-2`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const TextContainer = styled.div(props => [
  tw`flex flex-col w-full lg:w-7/12`,
  props.textOnLeft ? tw`lg:pr-12 lg:order-first` : tw`lg:pl-12 lg:order-last`
]);

const Subheading = tw(SubheadingBase)`mb-4`;
const HeadingTitle = tw(SectionHeading)`lg:text-left leading-tight`;
const Description = tw.p`max-w-md text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const QuoteContainer = tw.div`relative mt-10 lg:mt-20`;
const Quote = tw.blockquote`text-center lg:text-left text-sm sm:text-lg lg:text-xl xl:text-xl`;
const CustomerInfo = tw.div`mt-6 flex flex-col sm:flex-row items-center justify-center lg:justify-start`;
const CustomerProfilePicture = tw.img`rounded-full w-24 h-24`;
const CustomerTextInfo = tw.div`text-center lg:text-left sm:ml-6 mt-2 sm:mt-0`;
const CustomerName = tw.h5`font-semibold text-xl lg:text-2xl xl:text-3xl text-primary-500`;
const CustomerTitle = tw.p`font-medium text-secondary-100`;

const QuotesLeft = tw(QuotesLeftIcon)`w-6 h-6 opacity-75 text-primary-500 inline-block mr-1 -mt-3`;
const QuotesRight = tw(QuotesRightIcon)`w-6 h-6 opacity-75 text-primary-500 inline-block ml-1 -mt-3`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute w-32 top-0 left-0 -z-10 text-primary-500 opacity-25 transform -translate-x-full`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`absolute w-32 bottom-0 right-0 -z-10 text-pink-500 opacity-15 transform translate-x-2/3 translate-y-8`;

export default ({
  subheading = "",
  heading = "Nuestros servicios",
  description = "Here are what some of our amazing customers are saying about our hotels & tours. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  services = null,
  textOnLeft = false
}) => {
  /*
   * You can modify the testimonials shown by modifying the array below or passing in the testimonials prop above
   * You can add or remove objects from the array as you need.
   */
  const defaultServices = [
    {
      imageSrc: imgServiceRedes,
      profileImageSrc: imgProfileRedes,
      description: "Configuración y mantenimiento de redes LAN cableadas e Inalambricas. Balaceo de carga y accesos remotas. Servicios de gestión de redes, incluyendo monitoreo y optimizaciones de rendimiento.",
      customerName: "Comunicaciones",
      customerTitle: "Redes y Conectividad"
    },
    {
      imageSrc: imgServiceInfra,
      profileImageSrc:imgProfileInfra,
      description:"Mantenimiento de datacenters, cableado e instalaciones.",
      description2:"Servidores y Puestos de trabajo. Virtualizacion.",
      description3:"Licenciamiento y administracion de software. Limpieza y actualizaciones de hardware",
        customerName: "Infraestructura",
        customerTitle: "Equipamiento y datacenters"
    },
    {
      imageSrc: imgServiceSegInf,
      profileImageSrc: imgProfileSeg,
      description: "Firewalls y seguridad perimetral en red. Control de tráfico",
      description2: " Análisis de vulnerabilidades, Antivirus y Endpoint Security",
      description3: "Gestión de tokens y accesos VPN seguros,",

      customerName: "Infraestructura",
      customerTitle: "Equipamiento y datacenters"
    },
    {
      imageSrc: imgServiceCctv,
      profileImageSrc: imgProfileCctv,
      description:
        "Instalación, configuración y mantenimiento de sistemas de vigilancia. Instalación de cámaras interiores y exteriores, grabadoras digitales y monitores. Visualización remota y acceso seguro",
        customerName: "CCTV",
        customerTitle: "Seguridad perimetral"
    },
    {
      imageSrc:imgServiceIot,
      profileImageSrc:imgProfileIot ,
      description:`Diseño, desarrollo e implementación de soluciones IoT.`,
      description2: `Integración de datos y servicios cloud.`,
      description3: `Diseño y desarrollo de prototipos electrónicos.`,
      customerName: "Desarrollos",
      customerTitle: "Internet of Things"
    },

  ];

  if (!services || services.length === 0) services = defaultServices;

  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [imageSliderRef, setImageSliderRef] = useState(null);
  const [textSliderRef, setTextSliderRef] = useState(null);

  return (
    <Container>
      <Content>
        <TestimonialsContainer>
          <Testimonials>
            <Testimonial>
              <TestimonialImageSlider arrows={false} ref={setImageSliderRef} asNavFor={textSliderRef} fade={true}>
                {services.map((service, index) => (
                  <ImageAndControlContainer key={index}>
                    <Image imageSrc={service.imageSrc} />
                    <ControlContainer>
                      <ControlButton onClick={imageSliderRef?.slickPrev}>
                        <ChevronLeftIcon />
                      </ControlButton>
                      <ControlButton onClick={imageSliderRef?.slickNext}>
                        <ChevronRightIcon />
                      </ControlButton>
                    </ControlContainer>
                  </ImageAndControlContainer>
                ))}
              </TestimonialImageSlider>
              <TextContainer textOnLeft={textOnLeft}>
                <HeadingInfo tw="hidden lg:block" subheading={subheading} heading={heading} description={description} />
                <TestimonialTextSlider arrows={false} ref={setTextSliderRef} asNavFor={imageSliderRef} fade={true}>
                  {services.map((service, index) => (
                    <TestimonialText key={index}>
                      <QuoteContainer>
                        <Quote>
                          {service.description}
                        </Quote>
                        <Quote>
                          {service.description2}
                        </Quote>
                        <Quote>
                          {service.description3}
                        </Quote>
                      </QuoteContainer>
                      <CustomerInfo>
                        <CustomerProfilePicture src={service.profileImageSrc} alt={service.customerName} />
                        <CustomerTextInfo>
                          <CustomerName>{service.customerName}</CustomerName>
                          <CustomerTitle>{service.customerTitle}</CustomerTitle>
                        </CustomerTextInfo>
                      </CustomerInfo>
                    </TestimonialText>
                  ))}
                </TestimonialTextSlider>
              </TextContainer>
            </Testimonial>
          </Testimonials>
        </TestimonialsContainer>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

const HeadingInfo = ({ subheading, heading, description, ...props }) => (
  <div {...props}>
    {subheading ? <Subheading>{subheading}</Subheading> : null}
    <HeadingTitle>{heading}</HeadingTitle>
    <Description>{description}</Description>
  </div>
);