import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import Features from "components/features/DashedBorderSixFeatures";
import Footer from "components/footers/MiniCenteredFooter.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import MainFeature4 from "components/features/TwoColSingleFeatureWithStats4.js";
import Pilares from "../surit/Pilares.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import React from "react";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import { css } from "styled-components/macro"; //eslint-disable-line
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import tw from "twin.macro"; //eslint-disable-line

const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
const HighlightedText = tw.span`text-primary-500`;

export default () => (

  <AnimationRevealPage>
    <Hero />
    <MainFeature4 />
    <Features />
    {/* Agregados!!!*/}
    {/* --------!!!*/}
    <Pilares />
    {/* --------!!!*/}
    <Features />
    <MainFeature2 />
    <Portfolio />
    <Testimonial
      subheading="Testimonials"
      heading={
        <>
          Un lugar, <span tw="text-primary-500"> mil servicios</span>
        </>
      }
      description="Contamos con una gama de servicios para que pueda enfocarse en su negocio delegando el mantenimiento y gestion de sus servicios y activos de IT "
      testimonials={[
        {
          imageSrc:
            "./images/redes.jpg",
          profileImageSrc:
            "./images/redes.jpg",
          quote:
            "asdasdasdas",
          customerName: "Charlotte Hale",
          customerTitle: "CEO, Tesla Inc."
        },
        {
          imageSrc:
            "https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
          profileImageSrc:
            "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
          quote:
            "aaaa Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.",
          customerName: "Adam Cuppy",
          customerTitle: "Founder, Nestle"
        }
      ]}
      textOnLeft={true}
    />
    <FAQ
      imageSrc={customerSupportIllustrationSrc}
      imageContain={true}
      imageShadow={false}
      subheading="FAQs"
      heading={
        <>
          Do you have <span tw="text-primary-500">Questions ?</span>
        </>
      }
    />
    <Blog />
    <ContactUsForm />
    <Footer />
  </AnimationRevealPage>
);
