import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Features from "components/features/ThreeColWithSideImage.js";
import React from "react";
import tw from "twin.macro";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Features
        subheading={<Subheading>Nuestro enfoque</Subheading>}
        heading={
          <>
            ¿Que te <HighlightedText> ofrecemos</HighlightedText>?
          </>
        }
      />
    </AnimationRevealPage>
  );
}
